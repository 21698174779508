// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file einride/rd_operator_interface/v1/path.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * Path represents a path on WGS84-format.
 *
 * @generated from message einride.rd_operator_interface.v1.Path
 */
export class Path extends Message<Path> {
  /**
   * Path resource name
   * Pattern: sites/{site}/paths/{path}.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Display name for the path.
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * Geojson path, the format must correspond to the WSG84 format that the 'ad' UnmarshalGeoJSON function produces.
   * Said function can be found here: https://github.com/einride-autonomous/ads/blob/master/services/libs/geojson/unmarshal.go#L50-L95
   *
   * @generated from field: bytes path = 3;
   */
  path = new Uint8Array(0);

  /**
   * Version corresponds to the WSG84 proto version used when creating the path. Available versions can be found here:
   * https://github.com/einride-autonomous/proto-aet/tree/master/src/ad
   *
   * @generated from field: string version = 4;
   */
  version = "";

  constructor(data?: PartialMessage<Path>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.Path";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "path", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Path {
    return new Path().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Path {
    return new Path().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Path {
    return new Path().fromJsonString(jsonString, options);
  }

  static equals(a: Path | PlainMessage<Path> | undefined, b: Path | PlainMessage<Path> | undefined): boolean {
    return proto3.util.equals(Path, a, b);
  }
}

